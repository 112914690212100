<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="mr-2"
        min-width="36px"
        max-width="36px"
        v-on="on"
      >
        <v-icon> mdi-content-duplicate </v-icon>
      </v-btn>
    </template>
    <v-treeview
      :items="list"
      item-key="id"
      hoverable
      class="treeview"
    >
      <template #prepend="{ item }">
        <div
          class="d-flex align-center"
          @click.stop="item.action(item.id)"
        >
          <v-icon class="mr-2">
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </div>
      </template>
    </v-treeview>
    <!-- Старый функционал не удаляю (начало) -->
    <!-- <v-list>
      <v-list-item @click="createDTS">
        <v-icon>mdi-plus</v-icon> Создать ДТС-1
      </v-list-item>
      <v-divider v-if="list.length" />
      <v-list-item
        v-for="(item, index) in list"
        :key="index"
        @click="openDts(item.id)"
      >
        <v-icon>mdi-open-in-new</v-icon> <span class="px-1">{{ item.id }}</span> <span v-if="item.out_number">«{{ item.out_number }}»</span>
      </v-list-item>
    </v-list> -->
    <!-- конец -->
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { DTS, SD} from "@/http-routes";

export default {
  data() {
    return {
      list: [
        {
          name: "Создать ДТС-1",
          type: "DTS1",
          id: "1",
          icon: "mdi-plus",
          action: (id) => this.createDTS(id),
        },
        {
          name: "Создать ДТС-2",
          type: "DTS2",
          id: "2",
          icon: "mdi-plus",
          action: (id) => this.createDTS(id),
        },
        {
          name: "Создать СД ЭК",
          type: "SD",
          id: "3",
          icon: "mdi-plus",
          action: (id) => this.createSD(id),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
    }),
  },
  watch: {
    "selected.id": {
      handler() {
        this.getAllListByDeclarationId();
      },
    },
  },
  methods: {
    getAllListByDeclarationId() {
      return Promise.all([
        axios.get(`${DTS}declaration/getByDeclarationId?declarationId=${this.selected.id}`),
        axios.get(`${SD}declaration/getByDeclarationId?declarationId=${this.selected.id}`)
      ]).then(([
        {data: dts}, {data: sd}
      ]) => {
        this.list = this.list.map((item) => {
          const children = dts.filter(
            (elem) => elem.declaration_type === item.type
          );
          if (children.length !== 0) {
            return {
              ...item,
              children: this.formationDts(children),
            };
          } else {
            return {
              ...item,
              children: this.formationSd(sd),
            };
          }
        });
      });
    },   
    formationDts(children) {
      return children
        .map((item) => {
          return {
            ...item,
            name: `${item.id} «${item.out_number}»`,
            icon: "mdi-open-in-new",
            action: (id) => this.openDts(id),
          };
        })
        .sort((a, b) => b.id - a.id);
    },
    formationSd(sd) {
      return sd
        .map((item) => {
          return {
            ...item,
            name: `${item.id} «${item.out_number}»`,
            icon: "mdi-open-in-new",
            action: (id) => this.openSd(id),
          };
        })
        .sort((a, b) => b.id - a.id);
    },
    openDts(id) {
      const route = this.$router.resolve({
        path: `/dts/${id}`,
      });
      window.open(route.href, "_blank");
    },
    openSd(id) {
      const route = this.$router.resolve({
        path: `/statistics/${id}`,
      });
      window.open(route.href, "_blank");
    },
    createDTS(id) {
      this.$store
        .dispatch("dt/createDTS", id)
        .then(({ data }) => this.openDts(data))
        .then(() => this.getAllListByDeclarationId())
        .catch(() => this.$error());
    },
    createSD(id) {
      this.$store
        .dispatch("dt/createSD", id)
        .then(({ data }) => this.openSd(data))
        .then(() => this.getAllListByDeclarationId())
        .catch(() => this.$error());
    },
  },
};
</script>

<style scoped>
.treeview {
  background-color: #fff;
  cursor: pointer;
}
</style>
